import video from '../../assets/evolution.mp4'
import linkImages from '../../assets/links'
import {useEffect, useState} from "react";

export default function Home() {
  const links = ['twitter', 'opensea', 'etherscan']
  const [height, setHeight] = useState(0)
  useEffect(() => {
    setHeight(window.innerHeight)
  }, [])
  function handleJump(destination: string) {
    if ('twitter' === destination) {
      window.location.href = 'https://twitter.com/AIFightforlove'
    }
    if ('etherscan' === destination) {
      window.location.href = 'https://etherscan.io/address/0x677Ea3e33d79d68f9124f647f24e7914e89f2f49'
    }
    if ('opensea' === destination) {
      window.location.href = 'https://opensea.io/collection/robotbird'

    }
  }
  return (
    <div id='Home' className='flex w-screen h-full' style={{ height: height + 'px'}}>
      <div className='flex justify-center items-center w-1/2 overflow-hidden max-md:hidden'>
        <video
          muted={true}
          src={video}
          autoPlay={true}
          controls={false}
          loop={true}
          className='object-cover object-center w-full h-full'
        ></video>
      </div>
      <div className='bg-black1 flex w-1/2 flex-col text-white text-center justify-center items-center max-md:w-full max-md:bg-home-bg bg-no-repeat bg-cover bg-center'>
        <div className='hidden max-md:block absolute top-0 left-0 bg-black1 bg-opacity-70 w-full h-full'></div>
        <h1 className='text-[76px] mt-auto max-md:text-[7.422vw] z-10 font-title'>AFFL</h1>
        <p className='text-[white] mb-[58px] text-[22px] z-10'>&lt; Collection I : Robot bird &gt;</p>
        <hr className='bg-[white] w-[86px] z-10' />
        <p className='max-w-[490px] mt-[56px] text-16px leading-[19px] max-md:max-w-[80%] z-10'>
          AFFL=AI fight for love.<br />
          A pass to AFFL AI art community, tech optimist, and art enthusiast. Technical objects intertwine with humanity's nature. Let's create with love.
        </p>
        <div className='mt-auto mb-[140px] z-10 flex'>
          {links.map((item) => {
            return (
              <img
                className='w-[25px] h-[25px] mx-[31px] cursor-pointer'
                key={item}
                onClick={() => handleJump(item)}
                src={linkImages[item as keyof typeof linkImages]}
                alt={item}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
