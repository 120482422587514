import evolutionGif from '../assets/evolution.gif'
import Section from './Section'

export default function Evolution() {
  return (
    <Section bg={2} mReverse={true}>
      <img
        className='w-[42%] rounded-[16px] max-md:w-[325px] max-md:mb-[60px]'
        src={evolutionGif}
        alt='evolution gif'
      />
      <div className='text-white text-center ml-[6%] max-md:mx-auto max-md:mt-[60px] max-md:mb-[40px]'>
        <h1 className='text-[2.6vw] 2xl:text-[50px] max-md:text-[24px] font-black'>AFFL=AI FIGHT FOR LOVE</h1>
        <p className='text-16px mt-[36px] max-md:mt-[20px] max-md:text-[14px]'>
          As a Robot Bird holder, you will get access to an art & tech driven community.
        </p>
      </div>
    </Section>
  )
}
