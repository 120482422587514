import arrow from '../../assets/drop-down.png'
import { useState, useRef, useEffect } from 'react'

export default function FaqItem(props: { item: { question: string; answer: string } }) {
  const [fold, setFold] = useState(false)
  const [answerHeight, setAnswerHeight] = useState(0)
  const [heightStyle, setHeightStyle] = useState<{ height: string | number }>({
    height: 'auto',
  })
  const answerDom = useRef(null)
  useEffect(() => {
    setAnswerHeight((answerDom.current as unknown as HTMLElement).clientHeight)
  }, [])
  useEffect(() => {
    setHeightStyle({
      height: fold ? 0 : answerHeight,
    })
  }, [fold, answerHeight])
  return (
    <div className='border-0 border-solid border-b border-[#5c5c5c] mb-[28px]'>
      <div
        className='text-18px mb-[16px] flex justify-between items-center max-md:text-[16px]'
        onClick={() => {
          setFold(!fold)
        }}
      >
        {props.item.question}
        <img
          className={`duration-300 scale-100 translate-x-0 translate-y-0 skew-x-0 skew-y-0 ${
            fold ? 'rotate-90' : '-rotate-90'
          } cursor-pointer`}
          width='20'
          height='20'
          src={arrow}
          alt=''
        />
      </div>
      <div
        style={heightStyle}
        className={`overflow-hidden duration-300 text-16px text-[#c1c1c1] max-md:text-[14px]`}
        ref={answerDom}
      >
        <div className='mb-[28px]' dangerouslySetInnerHTML={{ __html: props.item.answer }}></div>
      </div>
    </div>
  )
}
