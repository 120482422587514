import discord from './discord.png'
import etherscan from './etherscan.png'
import twitter from './twitter.png'
import opensea from './opensea.png'

export default {
  discord,
  etherscan,
  twitter,
  opensea,
}
