import { ethers } from 'ethers'
import robotBirdABI from './Robotbird.json'

import WalletConnectProvider from '@walletconnect/web3-provider'
import {log} from "util";

const providers = new WalletConnectProvider({
  infuraId: '9e92c574cb904603932c65a1ca12da66',
})

const Ether: any = {}
const robotBirdAddress = '0x677Ea3e33d79d68f9124f647f24e7914e89f2f49'
let Lottery: ethers.Contract
let address = ''
let provider: ethers.providers.Web3Provider
let signer: ethers.providers.JsonRpcSigner

Ether.MetaMask = function () {
  this.getAddress = async () => {
    if (window.ethereum && localStorage.getItem('address') !== '') {
      try {
        provider = new ethers.providers.Web3Provider(window.ethereum)
        signer = provider.getSigner()
        Lottery = new ethers.Contract(robotBirdAddress, robotBirdABI as any, signer)
        address = await signer.getAddress()
        localStorage.setItem('address', address)
      } catch (e) {
        localStorage.setItem('address', '')
        address = ''
        console.log(e)
      }
    }
    return address
  }

  //The function of connecting to the wallet
  this.getConnect = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      window.ethereum
    } catch (error) {
      console.error('No Ethereum')
    }
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'eth_requestAccounts',
        })
      } catch (error) {
        console.error('User denied account')
      }
      provider = new ethers.providers.Web3Provider(window.ethereum)
      // test
      signer = provider.getSigner()
      Lottery = new ethers.Contract(robotBirdAddress, robotBirdABI as any, signer)
      localStorage.setItem('address', await signer.getAddress())
      address = await signer.getAddress()
      return await signer.getAddress()
    } else {
      console.log('no plugin installed')
      return 'No Ethereum'
    }
  }

  this.connectByScan = async () => {
    await providers.disconnect()
    try {
      const accounts = await providers.enable()
      const web3Provider = new ethers.providers.Web3Provider(providers)
      signer = web3Provider.getSigner();
      Lottery = new ethers.Contract(robotBirdAddress, robotBirdABI as any, signer)
      localStorage.setItem('address', await signer.getAddress())
      address = await signer.getAddress()
      return accounts[0]
    } catch (err) {
      console.log(err)
    }
  }

  this.disconnect = async () => {
    await providers.disconnect()
  }

  //The function of getting the money of user
  this.getBalance = async (wallet: { toString: () => string | Promise<string> }) => {
    const balance = await provider.getBalance(wallet.toString())
    return ethers.utils.formatEther(balance)
  }

  this.getCryptidMint = async (num: number) => {
    const n = num.toString(16)
    Lottery.mint(n)
  }

  this.getPublicPrice = async () => {
    const price = await Lottery.publicPrice()
    return Number(price) / 10 ** 18
  }

  this.getTotalSupply = async () => {
    const res = await Lottery.totalSupply()
    return Number(res)
  }

  this.getFreePerWallet = async () => {
    const res = await Lottery.freePerWallet()
    return Number(res)
  }

  this.getMaxFreeNumber = async () => {
    const res = await Lottery.maxFreeNumber()
    return Number(res)
  }

  this.getMaxBatchNumber = async () => {
    const res = await Lottery.maxBatchNumber()
    return Number(res)
  }

  this.cryptoMint = async (amount: number, cb: () => void) => {
    if (address === '') {
      cb()
      return
    }
    const tx = await Lottery.mint(amount, {
      value: ethers.utils.parseEther(`${0.0066 * amount}`)
    })
    await tx.wait()
  }
}

export default Ether
