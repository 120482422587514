export default function Section(props: {
  children: JSX.Element | JSX.Element[]
  bg: number
  mReverse?: boolean
  id?: string
}) {
  return (
    <div
      id={props.id}
      className={`overflow-hidden h-[50vw] w-full max-h-[960px] max-md:h-auto ${
        props.bg === 1 ? 'bg-black1' : 'bg-black2'
      }`}
    >
      <div
        className={`h-full mx-auto w-2/3 max-md:w-[325px] max-w-[1280px] items-center justify-center flex ${
          props.mReverse ? 'max-md:flex-col-reverse' : 'max-md:flex-col'
        }`}
      >
        {props.children}
      </div>
    </div>
  )
}
