import Section from '../Section'
import { useEffect, useState, useRef } from 'react'
import evolutionPngs, { allImages, indexedImages } from '../../assets/EvolutionManual'
import arrowPng from '../../assets/EvolutionManual/arrow.png'

export default function Evolution() {
  const baseUrl = 'https://infura-ipfs.io/ipfs/'
  const [process, setProcess] = useState(0)
  const [curBird, setCurBird] = useState(0)
  const [imageUrl, setImageUrl] = useState('')
  const [imageStyle, setImageStyle] = useState({
    left: '0%',
  })
  const [innerStyle, setInnerStyle] = useState({
    width: '0%',
  })
  const processRef = useRef(null)
  const imgRef = useRef(null)
  const [processWidth, setProcessWidth] = useState(0)
  const imageIndex = [992, 993, 995, 998, 999]

  useEffect(() => {
    const hash = evolutionPngs[0].hash
    setImageUrl(baseUrl + hash)
  }, [])

  useEffect(() => {
    setProcessWidth((processRef.current as unknown as HTMLElement).clientWidth)
  }, [])

  useEffect(() => {
    changeImage(curBird, process)
  }, [process])

  function handleProcess(e:React.MouseEvent<HTMLDivElement>) {
    const totalWidth = (processRef.current as unknown as HTMLElement).clientWidth
    const process = e.nativeEvent.offsetX / totalWidth * 100
    setProcess(Math.round(process))
    setInnerStyle({
      width: process + '%'
    })
    setImageStyle({
      left: process + '%'
    })
  }

  function changeImage(index: number, process: number) {
    const ids = [0, 17, 34, 50, 67, 83, 100]
    let realProcess = process
    for (let i = 0; i < ids.length; i++) {
      if (process >= ids[i]) {
        realProcess = ids[i]
      } else {
        break
      }
    }

    const path = `png${imageIndex[index]}_${realProcess}`
    setImageUrl(allImages[path as keyof typeof allImages])
  }

  function handleDown(e: PointerEvent) {
    e.preventDefault()
    let x = e.clientX
    let innerProcess = process

    document.onpointermove = function (e) {
      e.preventDefault()
      const offsetX = e.clientX - x
      x = e.clientX
      const temp = innerProcess + 100 * (offsetX / processWidth)
      if (temp >= 0 && temp <= 100) {
        setProcess(Math.round(temp))
        innerProcess = temp
        setImageStyle({
          left: temp + '%',
        })
        setInnerStyle({
          width: temp + '%',
        })
      }
    }
  }

  document.onmousemove = function (e) {
    e.preventDefault()
  }

  document.ontouchmove = function (e) {
    e.preventDefault()
  }

  document.onpointerup = function () {
    document.onpointermove = null
  }

  return (
    <Section bg={1} id='Evolution'>
      <div className='w-[44.5%] text-white text-center mr-[7%] max-md:mt-[60px] max-md:w-auto'>
        <h1 className='text-[2.6vw] 2xl:text-[50px] max-md:text-[28px] font-black'>Evolution process</h1>
        <p className='text-16px mt-[36px] max-md:mt-[20px] max-md:text-[14px]'>
          Powered by Disco Diffusion, after hundreds of tests and rendering, hooot, the robot bird came to life.
        </p>
      </div>
      <div className='w-1/2 max-md:mb-[60px] max-md:mt-[40px] max-md:w-[325px]'>
        <div className='flex'>
          <div className='w-[13.3%] justify-between flex flex-col h-[26vw] relative 2xl:h-[500px] max-md:w-[45px] max-md:h-[260px]'>
            {indexedImages.map((item, index) => (
              <img
                onClick={() => {
                  setCurBird(index)
                  changeImage(index, process)
                }}
                className='rounded-[10px] w-full max-md:rounded-[5px]'
                key={index}
                src={item}
                alt=''
              />
            ))}
          </div>
          <img
            className='ml-[30px] max-md:ml-[15px] w-[79.37%] rounded-[15px] md-max:w-[260px] max-md:h-[260px] max-md:rounded-[10px]'
            src={imageUrl}
            alt=''
          />
        </div>
        <div className='relative pl-[11.43%] mt-[26px]'>
          <p className='text-white text-18px font-bold mb-[5px]'>{process}%</p>
          <div onClick={handleProcess} ref={processRef} className='h-[12px] bg-[white] rounded-[6px] w-full'>
            <div className='h-full bg-[#96CCFF] rounded-[6px]' style={innerStyle}></div>
          </div>
          <div className='relative mt-[7px] w-[calc(100%-30px)]'>
            <img
              ref={imgRef}
              onPointerDown={(e) => handleDown(e as unknown as PointerEvent)}
              className='absolute cursor-pointer top-0 left-0'
              style={imageStyle}
              width={30}
              src={arrowPng}
              alt=''
            />
          </div>
        </div>
      </div>
    </Section>
  )
}
