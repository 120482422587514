import Section from '../Section'
import slideVideo from '../../assets/Mint/slide.mp4'
import slideGif from '../../assets/Mint/slide.gif'
import Ethers from '../../utils/ethers'
import {useEffect, useState} from 'react'
import styled from 'styled-components'
import {keyframes} from 'styled-components'
import loadingGif from '../../assets/Mint/loading.png'

import { ChangeEvent } from 'react'

const metaMask = new Ethers.MetaMask()
export default function Mint(props: {
  soldAmount: number,
  soldPrice: number,
  freePerWallet: number,
  batchPerWallet: number,
  maxFreeNumber: number,
  setSoldAmount: (number: number) => void,
  showLogin: () => void
}) {
  const [amount, setAmount] = useState(1)
  const [btnLoading, setBtnLoading] = useState(false)
  function inputChange(e: ChangeEvent<HTMLInputElement>) {
    const value = Number(e.target.value)
    changeAmount(value)
  }

  useEffect(() => {
    if (props.soldAmount < props.maxFreeNumber) {
      setAmount(2)
    } else {
      setAmount(1)
    }

  }, [props.soldAmount, props.maxFreeNumber])

  function changeAmount(value:number) {
    if (!isNaN(value) && value >= 1 && (value + props.soldAmount) < 10000) {
      if ((props.soldAmount <= props.maxFreeNumber - 2 && value <= props.freePerWallet)
        || (props.soldAmount > props.maxFreeNumber - 2 && value <= props.batchPerWallet + Math.max(props.maxFreeNumber - props.soldAmount, 0))
      ) {
        setAmount(Number(value))
      }
    }
  }


  async function mint() {
    const curTime = new Date().getTime()
    const startTime = 1655380800000
    if (curTime < startTime) {
      alert('mint after June 16, at 12:00 p.m.(utc)')
      return
    }

    if (btnLoading) return
    setBtnLoading(true)
    try {
      await metaMask.cryptoMint(amount, props.showLogin)
      alert('mint success')
      const newAmount = await metaMask.getTotalSupply()
      props.setSoldAmount(newAmount)
    } catch (e) {
      alert('mint failed')
      console.log(e)
    }
    setBtnLoading(false)
  }

  const processStyle = {
    width: (props.soldAmount / 100) + '%'
  }

  return (
    <Section bg={2} mReverse={true}>
      <div className='box-border flex-0 relative w-[30vw] h-[30vw] border border-solid border-[#AED9FE] rounded-[10px] p-[24px] 2xl:w-[576px] 2xl:h-[576px] max-md:w-[325px] max-md:h-[325px] max-md:mb-[60px]'>
        <video
          className='w-full rounded-[10px] max-md:hidden'
          src={slideVideo}
          loop={true}
          muted={true}
          controls={false}
          autoPlay={true}
        ></video>
        <img className='hidden max-md:block w-full rounded-[10px]' src={slideGif} alt='' />
      </div>
      <div className='text-white text-center ml-auto max-md:mt-[60px] max-md:mb-[40px] flex flex-col items-center max-md:w-[325px] w-[28.4vw] 2xl:w-[548px]'>
        <h1 className='w-[74.9%] text-left text-[2.6vw] 2xl:text-[50px] max-md:text-[28px] font-black max-md:w-[325px] text-center'>Mint Now</h1>
        <p className='text-left w-[74.9%] max-md:w-[325px] text-center'>The first 6666 are free.</p>
        <p className='text-left w-[74.9%] max-md:w-[325px] text-center'>You can mint up to 4 for free - then 0.0066ETH each</p>
        <p className='text-left w-[74.9%] max-md:w-[325px] text-center text-18px font-black'>June 16, at 12:00 p.m.(utc)</p>
        <div className='w-[74.9%] mt-[4.06vw] 2xl:mt-[78px] max-md:w-[325px] max-md:mt-[50px]'>
          <p className='flex justify-between text-16px mb-[10px]'>
            Total Sold <span className='text-[20px]'>{props.soldAmount}/10000</span>
          </p>
          <div className='w-full h-[12px] rounded-[6px] bg-[white]'>
            <div style={processStyle} className='bg-[#96CCFF] rounded-[6px] h-full'></div>
          </div>
        </div>
        <div className='justify-between box-border w-[74.9%] h-[56px] border border-solid border-[#96CCFF] rounded-[28px] flex items-center px-[20px] mt-[1.46vw] max-md:mt-[16px] 2xl:mt-[28px] max-md:w-[325px]'>
          <p className='2xl:text-16px mr-[10px] text-[0.83vw] max-md:text-[14px]'>Amount:</p>
          <div>
            <span
              onClick={() => {
                changeAmount(amount - 1)
              }}
              className='cursor-pointer text-18px w-[20px] h-full mr-[0.677vw] 2xl:mr-[13px] max-md:mr-[8px]'
            >
              -
            </span>
            {amount}
            <span
              onClick={() => {
                changeAmount(amount + 1)
              }}
              className='cursor-pointer text-18px w-[20px] h-full ml-[0.677vw] 2xl:ml-[13px] max-md:ml-[8px]'
            >
              +
            </span>
          </div>
        </div>
        <MintBtn
          onClick={mint}
          className={`${btnLoading ? 'loading' : ''}`}>
          <img src={loadingGif} alt=''/>
          {props.soldAmount + amount < props.maxFreeNumber ? 'Free Mint' : `Mint(${props.soldPrice}ETH)`}
        </MintBtn>
      </div>
    </Section>
  )
}

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const MintBtn = styled.div`
  cursor: pointer;
  color: black;
  border-radius: 28px;
  width: 74.9%;
  font-size: 18px;
  background: #96CCFF;
  line-height: 56px;
  height: 56px;
  margin-top: 1.72vw;
  @media screen and (min-width: 1920px) {
    margin-top: 33px;
  }
  @media screen and (max-width: 1023px) {
    width: 325px;
    margin-top: 20px;
  }
  img {
    display: none;
  }
  &:hover {
    opacity: 90%;
  }
  
  &.loading {
    font-size: 0;
    line-height: 0;
    display: flex;
    align-items: center;
    img {
      width: 30px;
      height: 30px;
      margin: auto;
      display: block;
      animation: ${rotate} 5s linear infinite;
    }
  }
`
