import styled from 'styled-components'
import {useEffect, useImperativeHandle, useState} from 'react'
import Modal from '../Modal'
import pngMetamask from '../../assets/navbar/metamask.png'
import pngWallet from '../../assets/navbar/wallet.png'
import Ethers from '../../utils/ethers'
import avatarImage from '../../utils/avatarImage'

export default function Navbar(props: {
  cRef: any,
  setVars: (soldPrice:number, soldAmount:number, freePerWallet:number, maxFreeNumber:number, batchPerWallet:number)=>void
}) {
  const metaMask = new Ethers.MetaMask()
  const parts = ['Home', 'Mint', 'Evolution', 'FAQ']
  const [showDropdown, setShowDropdown] = useState(false)
  const [visible, setVisible] = useState(false)
  const [address, setAddress] = useState('')
  const [showDownload, setShowDownload] = useState(false)
  const [showDisconnect, setShowDisconnect] = useState(false)

  useImperativeHandle(props.cRef, () => {
    return {
      showConnect: () => {
        setVisible(true)
      }
    }
  })

  function dropdown() {
    setShowDropdown(!showDropdown)
  }
  function handleClick() {
    if (window.innerWidth < 1023) {
      setShowDropdown(false)
    }
  }

  useEffect(() => {
    metaMask.getAddress().then((res: string) => {
      setAddress(res)
    })
  }, [])

  function removeEvent() {
    setShowDisconnect(false)
    window.removeEventListener('click', removeEvent)
  }
  function disconnectShow(e:any) {
    e.stopPropagation()
    setShowDisconnect(true)
    window.addEventListener('click', removeEvent)
  }

  async function disconnect() {
    setAddress('')
    localStorage.setItem('address', '')
    await metaMask.disconnect()
  }

  /**
   *
   * @param type 1 metamask 2 walletconnect
   */
  const connect = async (type: number) => {
    let address = ''
    if (type === 1) {
      address = await metaMask.getConnect()
      if (address === 'No Ethereum') {
        setShowDownload(true)
        return
      }
    } else {
      address = await metaMask.connectByScan()
    }
    setVisible(false)
    setShowDropdown(false)
    setAddress(address)
    let name:number = await metaMask.name()
    console.log(name)
    let soldPrice:number = await metaMask.getPublicPrice()
    let soldAmount:number = await metaMask.getTotalSupply()
    let freePerWallet:number = await metaMask.getFreePerWallet()
    let maxFreeNumber:number = await metaMask.getMaxFreeNumber()
    let batchPerWallet:number = await metaMask.getMaxBatchNumber()
    props.setVars(soldPrice, soldAmount, freePerWallet, maxFreeNumber, batchPerWallet)
  }
  return (
    <div
      className={`z-20 max-md:flex-col max-md:items-center max-md:justify-center max-md:h-[50px] absolute top-0 duration-200 text-[16px] w-full flex justify-end z-10 ${
        showDropdown ? 'max-md:h-full bg-[black] max-md:fixed' : ''
      }`}
    >
      <ul
        className={`w-1/2 flex max-md:flex-col max-md:items-center justify-center max-md:w-full  ${
          showDropdown ? 'max-md:flex' : 'max-md:hidden'
        }`}
      >
        {parts.map((item) => {
          return (
            <li key={item}>
              <a
                href={`#${item}`}
                className='text-white leading-[80px] mx-[1.06vw]'
                onClick={handleClick}
              >
                {item}
              </a>
            </li>
          )
        })}
      </ul>
      {address ?
        (<ConnectedAvatar
          onClick={disconnectShow}
          className={`${showDropdown ? 'dropdown' : ''} relative`}>
          <p className='pl-[30px] truncate'>{address}</p>
          <div onClick={disconnect} className={`${showDisconnect ? '' : 'hidden'} absolute top-[50px] bg-[white] text-[#333] text-center w-full rounded-[10px]`}>Disconnect</div>
        </ConnectedAvatar>) :
        <ConnectButton className={`${showDropdown ? '' : 'tiny-hidden'}`} onClick={() => setVisible(true)}>Connect
          Wallet</ConnectButton>
      }
      <MoreButton onClick={dropdown} className={`${showDropdown ? 'active' : ''}`}>
        <div className='inner'></div>
      </MoreButton>
      <Modal visible={visible} onClose={() => {
        setVisible(false)
        setShowDownload(false)
      }}>
        {showDownload ? <div className='bg-[white]'>
            <p className='text-[30px] pb-[28px] font-bold text-center'>Download Metamask</p>
            <div className='py-[50px] bg-[#f8f8f8] rounded-[16px] flex flex-col items-center justify-center'>
              <img src={pngMetamask} alt='' />
              <p className='pt-[14px] text-16px'>MetaMask</p>
              <p className='text-[#aaa] text-[12px] pb-[40px]'>Connect to MetaMask Wallet</p>
              <div
                onClick={() => {
                  window.location.href = 'https://metamask.io/download.html'
                }}
                className='cursor-pointer text-center rounded-[10px] text-16px font-bold w-[170px] h-[42px] leading-[42px] bg-[#96CCFF]'>Get MetaMask</div>
            </div>
          </div> :
          <div className='bg-[white]'>
            <p className='text-[30px] pb-[28px] font-bold text-center'>Connect Wallet</p>
            <ConnectWalletButton onClick={() => connect(1)}>
              <img className='w-[38px] h-[38px]' src={pngMetamask} alt=''/>
              <div>
                <p className='title'>MetaMask</p>
                <p className='note'>Connect to your MetaMask Wallet</p>
              </div>
            </ConnectWalletButton>
            <ConnectWalletButton onClick={() => connect(2)}>
              <img className='w-[38px] h-[38px]' src={pngWallet} alt=''/>
              <div>
                <p className='title'>WalletConnect</p>
                <p className='note'>Scan with WalletConnect wo connect</p>
              </div>
            </ConnectWalletButton>
          </div>}
      </Modal>
    </div>
  )
}

const ConnectWalletButton = styled.div`
  height: 90px;
  border-radius: 18px;
  width: 100%;
  display: flex;
  border: 1px solid #96ccff;
  margin-top: 18px;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: #96ccff;
  }
  img {
    margin-left: 18px;
    margin-right: 17px;
  }

  .title {
    font-size: 22px;
    font-weight: bold;
    color: #333;
  }
  .note {
    line-height: 19px;
    font-size: 14px;
    font-weight: 400;
    color: #696969;
  }
`

const ConnectedAvatar = styled.div`
  margin-top: -1.3vw;
  cursor: pointer;
  border-radius: 25px;
  font-size: 0.83vw;
  text-align: center;
  position: absolute;
  right: 1.6vw;
  top: 50%;
  background: #96ccff;
  width: 10.42vw;
  height: 2.6vw;
  line-height: 2.6vw;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333;
  padding-right: 1vw;
  
  img {
    width: 2.4vw;
    height: 2.4vw;
    border-radius: 2.25vw;
    margin-left: 0.2vw;
    margin-right: 0.5vw;
  }

  div {
    positon: absolute;
    top: 2.9vw;
    height: 2.6vw;
    line-height: 2.6vw;
    background: white;
    color: #333;
    text-align: center;
    width: 100%;
    border-radius: 10px;
  }

  @media screen and (min-width: 1920px) {
    font-size: 16px;
    top: 12px;
    margin-top: 0;
    line-height: 50px;
    height: 50px;
    width: 200px;
    
    img {
      width: 44px;
      height: 44px;
    }
    div {
      top: 60px;
      height: 50px;
      line-height: 50px;
    }
  }

  @media screen and (max-width: 1023px) {
    width: 122px;
    height: 40px;
    position: relative;
    font-size: 16px;
    line-height: 40px;
    margin-right: 60px;
    margin-left: auto;
    top: 15px;
    img {
      width: 34px;
      height: 34px;
      border-radius: 17px;
    }
    div {
      top: 45px;
      height: 40px;
      line-height: 40px;
      border-radius: 10px;
    }
    &.dropdown {
      display: none;
      width: 200px;
      height: 50px;
      position: relative;
      font-size: 16px;
      line-height: 50px;
      margin-right: auto;
      margin-left: auto;
      img {
        width: 44px;
        height: 44px;
        border-radius: 22px;
      }
    }
`

const ConnectButton = styled.div`
  margin-top: -1.3vw;
  cursor: pointer;
  border-radius: 25px;
  font-size: 0.83vw;
  text-align: center;
  position: absolute;
  right: 1.6vw;
  top: 50%;
  background: #96ccff;
  width: 10.42vw;
  height: 2.6vw;
  line-height: 2.6vw;
  @media screen and (min-width: 1920px) {
    font-size: 16px;
    top: 12px;
    margin-top: 0;
    line-height: 50px;
    height: 50px;
    width: 200px;
  }
  @media screen and (max-width: 1023px) {
    right: 0;
    width: 200px;
    height: 50px;
    position: relative;
    font-size: 16px;
    line-height: 50px;
    top: auto;
    
    &.tiny-hidden {
      display: none;
    }
  }
`

const MoreButton = styled.div`
  display: none;
  @media screen and (max-width: 1023px) {
    top: 20px;
    display: flex;
    position: absolute;
    align-items: center;
    right: 10px;
    margin-left: auto;
    padding: 0 3px;
    cursor: pointer;
    height: 30px;
    z-index: 22;

    &.login {
      margin-left: 10px;
    }
    .inner {
      width: 24px;
      height: 2px;
      background: white;
      position: relative;
      border-radius: 2px;
      transition-duration: 0.3s;

      &:before,
      &:after {
        content: ' ';
        width: 24px;
        height: 2px;
        background: white;
        position: absolute;
        border-radius: 2px;
        transition-duration: 0.3s;
      }

      &:before {
        top: -8px;
      }

      &:after {
        bottom: -8px;
      }
    }

    &.active {
      .inner {
        position: relative;
        height: 0;

        &:before {
          top: -1px;
          transform: rotate(45deg);
        }

        &:after {
          bottom: -1px;
          transform: rotate(-45deg);
        }
      }
    }
  }
`
