import FaqItem from './FaqItem'

export default function FAQ() {
  const faqs = [
    {
      question: 'When to launch?',
      answer: 'June 16, at 12:00 p.m.(utc)',
    },
    {
      question: 'Is there a presale or whitelist?',
      answer: `Nope!  Mint it directly, just for love.`,
    },
    {
      question: 'How many NFTs are available and how much each one costs?',
      answer: `There are 10,000 NFTs. The first 6,666 are free (gas fee needed). The remaining 3,334 are 0.0066 ETH each`,
    },
    {
      question: 'How many NFTs I can have per wallet?',
      answer: `For the free mint part, maximum 4 per wallet. For the remaining part, no limit.`,
    },
    {
      question: 'What is the smart contract address?',
      answer: `0x677Ea3e33d79d68f9124f647f24e7914e89f2f49`,
    },
  ]
  const items = faqs.map((item) => {
    return <FaqItem key={item.question} item={item} />
  })
  return (
    <div id='FAQ' className='text-white w-full bg-black1'>
      <div className='h-full mx-auto w-2/3 py-[152px] max-w-[1280px] items-center flex flex-col max-md:py-[60px]'>
        <h1 className='mb-[80px] text-[40px] max-md:mb-[40px] max-md:text-[28px] font-title'>FAQ</h1>
        <div className='w-full'>{items}</div>
      </div>
    </div>
  )
}
