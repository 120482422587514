import png992_0 from './992-0.png'
import png992_17 from './992-17.png'
import png992_34 from './992-34.png'
import png992_50 from './992-50.png'
import png992_67 from './992-67.png'
import png992_83 from './992-83.png'
import png992_100 from './992-100.png'
import png993_0 from './993-0.png'
import png993_17 from './993-17.png'
import png993_34 from './993-34.png'
import png993_50 from './993-50.png'
import png993_67 from './993-67.png'
import png993_83 from './993-83.png'
import png993_100 from './993-100.png'
import png995_0 from './995-0.png'
import png995_17 from './995-17.png'
import png995_34 from './995-34.png'
import png995_50 from './995-50.png'
import png995_67 from './995-67.png'
import png995_83 from './995-83.png'
import png995_100 from './995-100.png'
import png998_0 from './998-0.png'
import png998_17 from './998-17.png'
import png998_34 from './998-34.png'
import png998_50 from './998-50.png'
import png998_67 from './998-67.png'
import png998_83 from './998-83.png'
import png998_100 from './998-100.png'
import png999_0 from './999-0.png'
import png999_17 from './999-17.png'
import png999_34 from './999-34.png'
import png999_50 from './999-50.png'
import png999_67 from './999-67.png'
import png999_83 from './999-83.png'
import png999_100 from './999-100.png'

export const indexedImages = [png992_0, png993_0, png995_0, png998_0, png999_0]

export const allImages = {
  png992_0,
  png992_17,
  png992_34,
  png992_50,
  png992_67,
  png992_83,
  png992_100,
  png993_0,
  png993_17,
  png993_34,
  png993_50,
  png993_67,
  png993_83,
  png993_100,
  png995_0,
  png995_17,
  png995_34,
  png995_50,
  png995_67,
  png995_83,
  png995_100,
  png998_0,
  png998_17,
  png998_34,
  png998_50,
  png998_67,
  png998_83,
  png998_100,
  png999_0,
  png999_17,
  png999_34,
  png999_50,
  png999_67,
  png999_83,
  png999_100,
}

const images = [
  {
    path: '#992-0%.png',
    hash: 'Qmaed6ndSpnxTGZvSxveWNz8s7SHgQ2dMvYmRv7QT1PNwf',
    size: 6900,
  },
  {
    path: '#992-17%.png',
    hash: 'QmVD8y3ftr49FAYNcZ8Ed1mveYMD2MkzkG42uMMhMreYBf',
    size: 465540,
  },
  {
    path: '#992-34%.png',
    hash: 'QmfHcfsHQXnk63WbmotTqSbohd7NzKhSZjpjKUnM7CpYEU',
    size: 441393,
  },
  {
    path: '#992-50%.png',
    hash: 'QmRW7jbKavJqMrCCXjD84DPbh2FPtc6McCrDb6s3aaBjbj',
    size: 435043,
  },
  {
    path: '#992-67%.png',
    hash: 'QmSNJAKegVGPB81b6CwtLpgtPdjPL8tYAJvb2DC65y91wH',
    size: 417563,
  },
  {
    path: '#992-83%.png',
    hash: 'QmcNLy3z4N979iz9zrA9u2CgHxbTCBB2iT15hWv5BuwMW7',
    size: 430436,
  },
  {
    path: '#992-100%.png',
    hash: 'QmZw5b5fZpgmHDVfHpXjVNHQieW6dDsgvJwagmPX7buL6S',
    size: 471576,
  },

  {
    path: '#993-0%.png',
    hash: 'QmPmoUjGXhrpBuLjMgGMQNdATqbbNedPBj7aP5B5wPgrUT',
    size: 7452,
  },
  {
    path: '#993-17%.png',
    hash: 'QmRm9a3Xabkut857oAq8S4kjzttfe7HMvXeRf9uHU2HMd4',
    size: 469037,
  },
  {
    path: '#993-34%.png',
    hash: 'QmX5e4nVPzLZ6tJmzbzpvtVh6v8kuULzbUCr9JBLYsmU2t',
    size: 461643,
  },
  {
    path: '#993-50%.png',
    hash: 'QmWB5ZByWb3oTyT62N6BbDJ9gUxZhK8jkiJ8ttdhacTB6V',
    size: 433538,
  },
  {
    path: '#993-67%.png',
    hash: 'QmWDUsnmrhxuDXs1Nu8w8o6DBgACpmMYgxhD6RTSPAMnf5',
    size: 412181,
  },
  {
    path: '#993-83%.png',
    hash: 'QmT2uKW94KYMT5MZEwx9f6vKPHNfpKkZKjYzSh5v6WGgB6',
    size: 415323,
  },
  {
    path: '#993-100%.png',
    hash: 'QmQpgeuHuWdxQ3ZqQ5aoDjUWmS6iXg8iUeGWYLrNEYP9Xs',
    size: 462130,
  },

  {
    path: '#995-0%.png',
    hash: 'QmRZ6Z7VmiZYaZfynsnXe8vK8L5p8cuLArxwkRJbbaxfQF',
    size: 6900,
  },
  {
    path: '#995-17%.png',
    hash: 'QmUJev5jnQqYuW2RoUYr4paaHmk4BETgwTDuBMmCVD9Pmy',
    size: 451535,
  },
  {
    path: '#995-34%.png',
    hash: 'QmcYQrDBL3vaN92CcnTKzgrazvSrLdty5o8bK7zKBpiHHd',
    size: 441565,
  },
  {
    path: '#995-50%.png',
    hash: 'QmdqsrTKpnriqC1sxe4jYLPZCAhEd3f9fq6xFMmcad7Poo',
    size: 431171,
  },
  {
    path: '#995-67%.png',
    hash: 'QmZ6Zm7ZQQSxmKbs2EiU1jLHBVCJN1ACEagt8tigCYGr8j',
    size: 414717,
  },
  {
    path: '#995-83%.png',
    hash: 'QmVB8M3jtdJ8oMBjxVYXGffGrSsVT4s2tK65A6V3RA1u2v',
    size: 440643,
  },
  {
    path: '#995-100%.png',
    hash: 'QmV1C8s9ZHmEPFZFCcVcTpWPQER9ey8ZQv9AAqku6N29yW',
    size: 521862,
  },

  {
    path: '#998-0%.png',
    hash: 'QmX71nq3MydP7Ehuat4vEyjXZU5BQtwWYuCA6XyTX5vgcA',
    size: 7212,
  },
  {
    path: '#998-17%.png',
    hash: 'QmfGfWuNSotJf6gMuiAuC9pFdxtfLeMP2bBDQdsbrfUwCY',
    size: 480681,
  },
  {
    path: '#998-34%.png',
    hash: 'QmX1THuNeiqJBWFYwCdizC8UxXkscpZqyh7UVjp9AUmtZ9',
    size: 464221,
  },
  {
    path: '#998-50%.png',
    hash: 'QmbN3SpPznKDyroF9qtzcCxmy7pgpN2uPhZwCxxCWiJ9Bp',
    size: 451257,
  },
  {
    path: '#998-67%.png',
    hash: 'QmTuCsXR6dioFXanpe55ZtMS499nKRsepc2zDnL6Jg9Zv2',
    size: 443607,
  },
  {
    path: '#998-83%.png',
    hash: 'QmRg6KWQ6VxVduZWNeUFW9gGroCgoC2pKVtCL1bKZjr85d',
    size: 448270,
  },
  {
    path: '#998-100%.png',
    hash: 'QmXJ5k88i2KzCYqBBwhSP7uJPTmiQeWBDZHf7awyJcb1kV',
    size: 565517,
  },

  {
    path: '#999-0%.png',
    hash: 'QmYkvMTu4V6SRjYPJHrgJPmAgAuk8E2aiwzvK73vQdJW8a',
    size: 7307,
  },
  {
    path: '#999-17%.png',
    hash: 'QmPAvfP3LLeMRMWFZVz7T4K1AP2ee1YbXTLB3ixUP1rPNN',
    size: 485862,
  },
  {
    path: '#999-34%.png',
    hash: 'QmNuzRojmXAQxQFA8xvqLBEbnALidKnKfeu6bYvPqV1sc4',
    size: 450616,
  },
  {
    path: '#999-50%.png',
    hash: 'QmSxDwr9kv27kyVhFeNbF7KGQ1uSJL6HV8Tgmokmj22sC8',
    size: 417774,
  },
  {
    path: '#999-67%.png',
    hash: 'QmNza7g7i962KJd2cdDXpScV9fgiiz31LRkamK8xvqHCTv',
    size: 370063,
  },
  {
    path: '#999-83%.png',
    hash: 'QmdRM4axTpnYj7hYKet7W57cFgUR3UYf3DNwn2XrgAVSCo',
    size: 370418,
  },
  {
    path: '#999-100%.png',
    hash: 'QmPMkoAXASgHCpKR73Dez4HAM7LRMaTbUEHD8JA34TJuer',
    size: 411028,
  },
]

export default images
