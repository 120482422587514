import { useEffect, useState } from 'react'
import pngCLose from '../assets/close.png'
interface Props {
  visible: boolean
  children: JSX.Element | JSX.Element[] | string
  onClose: () => void
}

export default function Modal(props: Props) {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setVisible(props.visible)
  }, [props.visible])

  function closeModal() {
    props.onClose()
    setVisible(false)
  }

  return (
    <>
      {visible && (
        <div className='z-[999] w-screen h-screen fixed top-0 left-0 flex items-center justify-center'>
          <div
            className='w-screen h-screen bg-[#000000] opacity-70 absolute'
            onClick={closeModal}
          ></div>
          <div
            className={`rounded-[16px] w-[422px] max-md:w-[362px] m-auto z-[1] relative pt-[36px] px-[30px] pb-[33px] bg-[white]`}
          >
            {props.children}
            <img
              className='absolute w-[14px] h-[14px] top-[24px] right-[24px] cursor-pointer'
              src={pngCLose}
              onClick={closeModal}
              alt='close'
            />
          </div>
        </div>
      )}
    </>
  )
}
