import './App.css'
import Navbar from './components/Navbar'
import Home from './components/Home'
import Mint from './components/Mint'
import EvolutionManual from './components/EvolutionManual'
import Evolution from './components/Evolution'
import FAQ from './components/FAQ'
import {useEffect, useState, useRef} from "react";
import {ethers} from "ethers";
import contractAddress from "./utils/address";
import robotAbi from "./utils/Robotbird.json";

function App() {
  const [soldAmount, setSoldAmount] = useState(0)
  const [soldPrice, setSoldPrice] = useState(0)
  const [freePerWallet, setFreePerWallet] = useState(0)
  const [batchPerWallet, setBatchPerWallet] = useState(0)
  const [maxFreeNumber, setMaxFreeNumber] = useState(0)

  const childRef:any = useRef(null)

  useEffect(() => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(
        contractAddress,
        robotAbi,
        provider
      );
      contract.publicPrice().then((res: number) => {
          setSoldPrice(Number(res) / 10 ** 18)
        }
      )
      contract.totalSupply().then((res: number) => {
        setSoldAmount(Number(res))
      })
      contract.freePerWallet().then((res: number) => {
        setFreePerWallet(Number(res))
      })
      contract.maxFreeNumber().then((res: number) => {
        setMaxFreeNumber(Number(res))
      })
      contract.maxBatchNumber().then((res: number) => {
        setBatchPerWallet(Number(res))
      })
    } catch (e) {
      console.log(e)
    }
  }, [])

  function setVars (soldPrice:number, soldAmount:number, freePerWallet:number, maxFreeNumber:number, batchPerWallet:number) {
    setSoldPrice(soldPrice)
    setSoldAmount(soldAmount)
    setFreePerWallet(freePerWallet)
    setMaxFreeNumber(maxFreeNumber)
    setBatchPerWallet(batchPerWallet)
  }

  function showLogin() {
    console.log(childRef)
    ;(childRef.current as any).showConnect()
  }

  return (
    <div className='App'>
      <Navbar cRef={childRef} setVars={setVars} />
      <Home />
      <Mint setSoldAmount={setSoldAmount} showLogin={showLogin} maxFreeNumber={maxFreeNumber} batchPerWallet={batchPerWallet} soldPrice={soldPrice} soldAmount={soldAmount} freePerWallet={freePerWallet}/>
      <EvolutionManual />
      <Evolution />
      <FAQ />
    </div>
  )
}
// TODO import font-family

export default App
